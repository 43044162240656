<template>
    <div class="bending-moment-calculator">
      <h2>Bending Moment Calculator</h2>
      <div class="input-fields">
        <div class="input-group">
          <label for="beamLength">Beam Length (m):</label>
          <input type="number" id="beamLength" v-model.number="beamLength">
        </div>
  
        <div class="input-group">
          <label for="supportPositionA">Support A Position (m):</label>
          <input type="number" id="supportPositionA" v-model.number="supportPositionA">
        </div>
  
        <div class="input-group">
          <label for="supportPositionB">Support B Position (m):</label>
          <input type="number" id="supportPositionB" v-model.number="supportPositionB">
        </div>
  
        <div class="input-group">
          <label for="loadMagnitude">Load Magnitude (N):</label>
          <input type="number" id="loadMagnitude" v-model.number="loadMagnitude">
        </div>
  
        <div class="input-group">
          <label for="loadPosition">Load Position (m):</label>
          <input type="number" id="loadPosition" v-model.number="loadPosition">
        </div>
  
        <button @click="calculateBendingMoment">Calculate</button>
      </div>
      <div class="results-container" v-if="bendingMomentResult !== null">
        <p>Bending Moment: {{ bendingMomentResult }} Nm</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BendingMomentCalculator',
    data() {
      return {
        beamLength: 0,
        supportPositionA: 0,
        supportPositionB: 0,
        loadMagnitude: 0,
        loadPosition: 0,
        bendingMomentResult: null,
      };
    },
    methods: {
      calculateBendingMoment() {
        if (this.validateInputs()) {
          this.bendingMomentResult = this.computeBendingMoment();
        } else {
          alert('Invalid input');
        }
      },
      validateInputs() {
        return (
          this.beamLength > 0 &&
          this.supportPositionA >= 0 &&
          this.supportPositionB > this.supportPositionA &&
          this.supportPositionB <= this.beamLength &&
          this.loadMagnitude > 0 &&
          this.loadPosition >= 0 &&
          this.loadPosition <= this.beamLength
        );
      },
      computeBendingMoment() {
        // Placeholder for real bending moment calculation logic
        return this.loadMagnitude * (this.loadPosition - (this.supportPositionA + this.supportPositionB) / 2);
      },
    },
  };
  </script>
  
  <style scoped>
  .bending-moment-calculator {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .input-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .results-container {
    margin-top: 20px;
    /*background-color: #f8f8f8;*/
    padding: 10px;
    border-radius: 4px;
  }
  </style>
  