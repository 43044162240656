<template>
  <div id="app" :class="{ 'dark-mode': darkMode }">
    <header>
      <nav>
        <ul>
          <li><router-link to="/">Home</router-link></li>
          <li><router-link to="/technical-report">Technical Report Guide</router-link></li>
          <li><router-link to="/tools">Tools</router-link></li>
        </ul>
        <button @click="toggleDarkMode">Toggle Dark Mode</button>
      </nav>
    </header>
    
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      darkMode: false
    };
  },
  created() {
    this.darkMode = localStorage.getItem('darkMode') === 'true';
    this.updateDarkMode();
  },
  methods: {
    toggleDarkMode() {
      this.darkMode = !this.darkMode;
      localStorage.setItem('darkMode', this.darkMode);
      this.updateDarkMode();
    },
    updateDarkMode() {
      const className = 'dark-mode';
      if (this.darkMode) {
        document.body.classList.add(className);
      } else {
        document.body.classList.remove(className);
      }
    }
  }
}
</script>

<style>
/* Global styles and import main CSS */
@import './assets/css/style.css';

/* Add dark mode specific styles here */
.dark-mode {
  /* Example dark mode styles */
  background-color: #333;
  color: #fff;
}
</style>
