import { createApp } from 'vue';
import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';
import HomePage from './components/HomePage.vue';
import TechnicalReportPage from './components/TechnicalReportPage.vue';
import ToolsPage from './components/ToolsPage.vue';

// Set up routes
const routes = [
  { path: '/', component: HomePage },
  { path: '/technical-report', component: TechnicalReportPage },
  { path: '/tools', component: ToolsPage }
];

// Create the router instance
const router = createRouter({
  history: createWebHashHistory(),
  routes
});

// Create the Vue application with App as the root component
const app = createApp(App);

// Use the router
app.use(router);

// Mount the app
app.mount('#app');
