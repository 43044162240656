<template>
  <div>
    <h2>Tools</h2>
    <div>
      <label for="calculatorType" class="calculator-label">Select Tool:</label>
      <select id="calculatorType" v-model="selectedTool">
        <option value="kgToNewtons">Kilograms to Newtons</option>
        <option value="bendingMoment">Bending Moment</option>
        <!-- Add more options for different calculators here -->
      </select>
    </div>

    <component :is="selectedToolsComponent"></component>
  </div>
</template>

<script>
import KgToNewtonsCalculator from './calculators/KgToNewtonsCalculator.vue';
import BendingMomentCalculator from './calculators/BendingMomentCalculator.vue';

export default {
  name: 'ToolsPage',
  components: {
    KgToNewtonsCalculator,
    BendingMomentCalculator,
    // Register other calculator components here
  },
  data() {
    return {
      selectedTool: 'kgToNewtons',
    };
  },
  computed: {
    selectedToolsComponent() {
      switch (this.selectedTool) {
        case 'kgToNewtons':
          return KgToNewtonsCalculator;
        case 'bendingMoment':
          return BendingMomentCalculator;
        // Add cases for other calculators
        default:
          return null;
      }
    },
  },
};
</script>

<style scoped>
/* Add any CSS styles specific to CalculationsPage here */
</style>
