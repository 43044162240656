<template>
    <div class="calculator">
      <h2>Kilograms to Newtons Calculator</h2>
      <div class="calculator-container">
        <label for="kgInput" class="calculator-label">Enter kilograms (kg):</label>
        <input type="number" id="kgInput" v-model.number="kg" class="calculator-input">
        <button @click="convert" class="calculator-button">Convert</button>
        <p v-if="result !== null" class="calculator-result">Result: {{ result }} N</p>
        <p v-if="result === null && converted" class="calculator-invalid">Invalid input.</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'KgToNewtonsCalculator',
    data() {
      return {
        kg: null,
        result: null,
        converted: false,
      };
    },
    methods: {
      convert() {
        if (this.kg !== null && !isNaN(this.kg)) {
          this.result = (this.kg * 9.81).toFixed(2); // Conversion logic
          this.converted = true;
        } else {
          this.result = null;
          this.converted = true;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .calculator-container {
    text-align: center;
  }
  
  .calculator-input,
  .calculator-button {
    margin: 10px;
  }
  
  .calculator-result,
  .calculator-invalid {
    margin-top: 20px;
  }
  </style>
  