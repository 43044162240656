<template>
    <div>
      <h1>Welcome to Mechanical Engineering Tools</h1>
      <p>Select a tool from the navigation menu to get started.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HomePage',
    // Add any specific JavaScript logic for HomePage here
    // For example, a method or data property unique to the HomePage
  };
  </script>
  
  <style scoped>
  /* Add any CSS styles specific to HomePage here */
  /* Example: */

  
  p {
    text-align: center;
    margin-top: 20px;
  }
  </style>
  